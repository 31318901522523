// GO CHECK
/** @see https://github.com/mui-org/material-ui-x/blob/ef1ea4be12d9b776e4cababf18b8e4e70fb5f677/packages/grid/_modules_/grid/constants/localeTextConstants.ts */
export const localeDataGrid = {
    // Root
    /** 新版的字會切到header，先關閉 */
    noRowsLabel: '',
    noResultsOverlayLabel: '找不到任何資訊',
    errorOverlayDefaultLabel: '發生錯誤',
    // Density selector toolbar button text
    toolbarDensity: 'Density',
    toolbarDensityLabel: 'Density',
    toolbarDensityCompact: '簡潔',
    toolbarDensityStandard: '標準',
    toolbarDensityComfortable: '舒適',
    // Columns selector toolbar button text
    toolbarColumns: 'Columns',
    toolbarColumnsLabel: 'Select columns',
    // Filters toolbar button text
    toolbarFilters: '篩選器',
    toolbarFiltersLabel: '顯示篩選器',
    toolbarFiltersTooltipHide: '隱藏篩選器',
    toolbarFiltersTooltipShow: '顯示篩選器',
    toolbarFiltersTooltipActive: count => count !== 1 ? `${count} active filters` : `${count} active filter`,
    // Export selector toolbar button text
    toolbarExport: 'Export',
    toolbarExportLabel: 'Export',
    toolbarExportCSV: 'Download as CSV',
    // Columns panel text
    columnsPanelTextFieldLabel: 'Find column',
    columnsPanelTextFieldPlaceholder: 'Column title',
    columnsPanelDragIconLabel: 'Reorder column',
    columnsPanelShowAllButton: 'Show all',
    columnsPanelHideAllButton: 'Hide all',
    // Filter panel text
    filterPanelAddFilter: 'Add filter',
    filterPanelDeleteIconLabel: 'Delete',
    filterPanelOperators: 'Operators',
    filterPanelOperatorAnd: 'And',
    filterPanelOperatorOr: 'Or',
    filterPanelColumns: 'Columns',
    filterPanelInputLabel: 'Value',
    filterPanelInputPlaceholder: 'Filter value',
    // Filter operators text
    filterOperatorContains: 'contains',
    filterOperatorEquals: 'equals',
    filterOperatorStartsWith: 'starts with',
    filterOperatorEndsWith: 'ends with',
    filterOperatorIs: 'is',
    filterOperatorNot: 'is not',
    filterOperatorAfter: 'is after',
    filterOperatorOnOrAfter: 'is on or after',
    filterOperatorBefore: 'is before',
    filterOperatorOnOrBefore: 'is on or before',
    // Filter values text
    filterValueAny: 'any',
    filterValueTrue: 'true',
    filterValueFalse: 'false',
    // Column menu text
    columnMenuLabel: '選單',
    columnMenuShowColumns: '選擇顯示欄位',
    columnMenuFilter: '篩選',
    columnMenuHideColumn: '隱藏',
    columnMenuUnsort: '不排序',
    columnMenuSortAsc: '升序排列',
    columnMenuSortDesc: '降序排列',
    // Column header text
    columnHeaderFiltersTooltipActive: count => count !== 1 ? `${count} active filters` : `${count} active filter`,
    columnHeaderFiltersLabel: 'Show filters',
    columnHeaderSortIconLabel: 'Sort',
    // Rows selected footer text
    footerRowSelected: count => '',
    // Total rows footer text
    footerTotalRows: 'Total Rows:',
    // Total visible rows footer text
    footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
    // Checkbox selection text
    checkboxSelectionHeaderName: 'Checkbox selection',
    // Boolean cell text
    booleanCellTrueLabel: 'true',
    booleanCellFalseLabel: 'false',
};
